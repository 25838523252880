/**
 * @name URLBuilder
 *
 * Provides functions for building URLs
 */

import buildUrl from './buildUrl'

const URLS = {
  //
  // Builder
  //
  editWebsiteDocumentSectionURL: '/builder/websites/:websiteId/documents/:documentId/:sectionName/:subSectionName',
  editWebsiteDocumentURL: '/builder/websites/:websiteId/documents/:documentId',
  newDocumentURL: '/builder/documents/new',
  newWebsiteDocumentURL: '/builder/websites/:websiteId/documents/new',

  //
  // Dashboard
  //
  accountURL: '/dashboard/settings/account',
  checkoutPreviewURL: '/dashboard/settings/checkout-preview',
  dashboardURL: '/dashboard/',
  dsarConfirmationURL: '/notify/:documentId/success',
  dudaIntegrationURL: '/dashboard/integrations/duda',
  loginURL: '/user/login',
  logoutURL: '/user/logout',
  notFoundURL: '/dashboard/page-not-found',
  planInfoURL: '/dashboard/settings/plan-info',
  subscriptionManagerURL: '/dashboard/settings/subscription-manager',
  threeDSAuthCompleteURL: '/dashboard/3ds-auth-complete',
  threeDSAuthFailedURL: '/dashboard/3ds-auth-failed',
  signUpURL: '/user/sign-up',

  // TODO: react-router complains if nested absolute routes don't
  // start with the same prefix, so I have to use :id here instead of
  // :websiteId (or :websiteID). We'll have to do a big cleanup to make
  // everything uniform.
  domainRulesURL: '/dashboard/website/:id/domain-rules',
  policyDetailsURL: '/dashboard/website/:id/:policySlug',
  supportURL: '/dashboard/support',
  websiteCMPOnboardingURL: '/dashboard/website/:id/cmp-onboarding',
  websiteVendorsPageURL: '/dashboard/website/:id/vendors',
  websiteDashboardURL: '/dashboard/website/:id',
  websitesURL: '/dashboard/websites',
}

export default Object.entries(URLS).reduce((urls, [name, template]) => ({
  ...urls,
  [name]: buildUrl(template),
}), {})
